/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Button, ContactForm, Fullmap, FullmapWrap, Subtitle, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Veronika&Jan "}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--l8w pt--60" style={{"backgroundColor":"rgba(255, 255, 255, 1)","paddingBottom":0,"marginBottom":0}} name={"w4efy8tbp5q"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"backgroundColor":"var(--color-custom-2)"}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/946c3233b0464075ab072da3f87773e2_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/946c3233b0464075ab072da3f87773e2_s=350x_.png 350w, https://cdn.swbpg.com/t/38064/946c3233b0464075ab072da3f87773e2_s=660x_.png 660w, https://cdn.swbpg.com/t/38064/946c3233b0464075ab072da3f87773e2_s=860x_.png 860w, https://cdn.swbpg.com/t/38064/946c3233b0464075ab072da3f87773e2_s=1400x_.png 1400w, https://cdn.swbpg.com/t/38064/946c3233b0464075ab072da3f87773e2_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/0ebeabf093e4481dbeda476501bd653e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/0ebeabf093e4481dbeda476501bd653e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38064/0ebeabf093e4481dbeda476501bd653e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38064/0ebeabf093e4481dbeda476501bd653e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38064/0ebeabf093e4481dbeda476501bd653e_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--60" style={{"paddingBottom":43}} name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--102" content={"<span style=\"color: var(--color-custom-2);\">Budeme se brát! <br>Oslavte s námi ten nejhezčí den našeho života pod šumavským nebem.</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pl--02" style={{"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":0}} name={"duleziteinfo"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"backgroundColor":"var(--color-custom-2)"}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box pt--25" content={"<span style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Důležité informace</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--18" style={{"maxWidth":730,"paddingBottom":0}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">PŘÍJEZD</span><br>Na místo je možné dorazit autem. Parkovací místa budou označena. Pro hosty, kteří budou z blízkého okolí, může být zajištěn odvoz (na svatbu i ze svatby).<br><br><span style=\"font-weight: bold;\">UBYTOVÁNÍ</span><br>Ubytovacích míst na Hajnýho stodole bohužel není mnoho.&nbsp;<br>Pro všechny, kteří budou potřebovat ubytovat v blízkém okolí, rádi ubytování zajistíme.<br>Prosíme tedy vyplnit do dotazníku informaci, že chcete ubytování zajistit <br>a od kterého dne.&nbsp;<br>Těm, kteří se budou chtít zdržet déle (před či po svatbě), zkusíme také poptat ubytování. Tyto dny navíc si každý host bude platit sám.<br>Pro odvážlivce je možnost přespat ve stanu.<br><br><br><span style=\"font-weight: bold;\">DĚTI NA SVATBĚ</span><br>&nbsp;Byli&nbsp; bychom rádi, abyste si s námi užili svatební den na plno.<br>Proto prosíme, zda by bylo možné si zařídit hlídání pro své ratolesti.<br><br><span style=\"font-weight: bold;\">SVATEBNÍ DAR</span><br>Se svatebním darem si prosím nelamte hlavu. <br>Můžete nám finančně přispět na svatební cestu.<br><br><span style=\"font-weight: bold;\">DRESS CODE</span><br>Naše svatba nemá stanovený dress code. <br>Prosíme svatebčany, aby zůstali svátečně oblečení během celého svatebního dne. Co se týče samotného oblečení, zvažte vhodnost barev, jakými jsou bíla, černá a červená.<br>Inspirací pro možnou volbu odstínů šatů může být to, že se celá svatba ponese v níže uvedených (zobrazených) tónech.<br>Dále také vezměte v úvahu, že svatba se koná z části v přírodě (zvolte vhodnou obuv).<br><br><br>&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"0q0v0i66rwzo"}>
          
          <ColumnWrap className="column__flex --center el--3 pt--10" style={{"backgroundColor":"var(--color-custom-2)"}} columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/6ebe8261e0824d08b5eb42963dce1456_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/6ebe8261e0824d08b5eb42963dce1456_s=350x_.png 350w, https://cdn.swbpg.com/t/38064/6ebe8261e0824d08b5eb42963dce1456_s=660x_.png 660w, https://cdn.swbpg.com/t/38064/6ebe8261e0824d08b5eb42963dce1456_s=860x_.png 860w, https://cdn.swbpg.com/t/38064/6ebe8261e0824d08b5eb42963dce1456_s=1400x_.png 1400w, https://cdn.swbpg.com/t/38064/6ebe8261e0824d08b5eb42963dce1456_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--20" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"informace"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--48 pb--16" content={"Harmonogram"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"informace-2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"11:00 -12:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16" anim={null}>
              
              <Text className="text-box fs--16" content={"Příjezd svatebčanů"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"13:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box text-box--left" content={"Obřad - gratulace, svatební špalír, skupinové focení"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"14:15"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Přivítání hostů, svatební proslovy&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"14:30"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Společná polévka a svatební raut"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"16:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Krájení dortu"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"17:30"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"První novomanželský tanec"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"18:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Novomanželské hry"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box fs--36" content={"19:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Grilování&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"potvrditucast"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 pb--25" innerClassName="pb--02 pl--0 pr--0 pt--0" style={{"paddingTop":33}} href={"/potvrdit-ucast"} content={"<span style=\"color: var(--black);\">Prosím, potvrďte účast do 21.06.2024</span><br>"}>
              </Button>

              <ContactForm className="w--400 pb--20" name={"iu3lmxreuua"} action={"contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte svůj e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Kolik vás bude","type":"number"},{"name":"Chcete zajistit ubytování","type":"select","options":[{"id":"h98smpep8itj","value":"Ano, v penizionu od úterý"},{"id":"8ruoa1bwu2le","value":"Ano, v penizionu od středy"},{"id":"ikttbgny9cyn","value":"Budu ve vlastním stanu"},{"id":"sfabomhj1lml","value":"Ne"},{"id":"xgmg3kb7h1mm","value":"Ano, chci ubytování a i v jiné dny "}],"required":true},{"name":"Zajistit odvoz","type":"select","options":[{"id":"rgbuw747mii2","value":"Ano, na cestu tam"},{"id":"3e2f7k5wqqa7","value":"Ano, na cestu zpět"},{"id":"fzvcaqpui1j6","value":"Ano, na cestu tam a i zpět"},{"id":"wmbd5hf9mitn","value":"Ne děkuji"}]},{"type":"text","name":"Písničky","placeholder":"Tři tvoje/ vaše oblíbené písničky"},{"name":"Chcete nám něco vzkázat","type":"textarea","required":true,"placeholder":"Potravinové alergie, speciální požadavky na ubytování atd .."},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":45}} name={"mapa"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"KONTAKT"}>
              </Title>

              <Text className="text-box text-box--center fs--16" style={{"maxWidth":604}} content={"<span style=\"color: var(--black);\">V případě jakéhokoliv dotazu se prosím </span><br><span style=\"color: var(--black);\">obraťte na svatební koordinátorky, </span><br><span style=\"color: var(--black);\">popřípadě na nevěstu:</span><br><span style=\"color: var(--black);\">&nbsp;Marta: 723908173<br>Šárka: </span><span style=\"color: var(--black);\">604269593<br>ne.mozne21@gmail.com<br>Veronika: 736781017</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"bhdu3jav7ct"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: var(--color-custom-1--15);\">České Žleby 52, 384 42 Lenora-Volary<br>(na Hajného stodolu musíte vždy jet přes Lenoru)<br></span>"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"10"} place={"České Žleby 52, 384 42 Lenora-Volary"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="--center" style={{"paddingTop":0}} name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10 pb--10" content={"Těšíme se na Vás!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--02" layout={"l12"} name={"lrsw59jcdrc"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/6ce2d24fabd7431e9b607f7682427c06_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/6ce2d24fabd7431e9b607f7682427c06_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38064/6ce2d24fabd7431e9b607f7682427c06_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38064/6ce2d24fabd7431e9b607f7682427c06_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/6ea5a59a8311440c88109059679f019a_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/6ea5a59a8311440c88109059679f019a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38064/6ea5a59a8311440c88109059679f019a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38064/6ea5a59a8311440c88109059679f019a_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/82accf5abd684af5a746e49377231dc5_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/82accf5abd684af5a746e49377231dc5_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38064/82accf5abd684af5a746e49377231dc5_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38064/82accf5abd684af5a746e49377231dc5_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/15909b4bbca44d8e9435dacf4668543d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/15909b4bbca44d8e9435dacf4668543d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38064/15909b4bbca44d8e9435dacf4668543d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38064/15909b4bbca44d8e9435dacf4668543d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38064/15909b4bbca44d8e9435dacf4668543d_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/afe9f125ec074fd6a923a751e76f4b75_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/afe9f125ec074fd6a923a751e76f4b75_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38064/afe9f125ec074fd6a923a751e76f4b75_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38064/afe9f125ec074fd6a923a751e76f4b75_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/a2abbf6fb7c745b98de2c38c3d2629ef_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/a2abbf6fb7c745b98de2c38c3d2629ef_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/38064/a2abbf6fb7c745b98de2c38c3d2629ef_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/38064/a2abbf6fb7c745b98de2c38c3d2629ef_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w" style={{"backgroundColor":"rgba(255, 255, 255, 1)","paddingTop":48,"paddingBottom":9}} name={"p336a8i2t0d"}>
          
          <ColumnWrap className="column__flex --center el--3 pt--20" style={{"backgroundColor":"var(--color-custom-2)"}} columns={"3"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38064/0e80cae2d9aa4589ac8a0aaacfebe520_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/38064/0e80cae2d9aa4589ac8a0aaacfebe520_s=350x_.png 350w, https://cdn.swbpg.com/t/38064/0e80cae2d9aa4589ac8a0aaacfebe520_s=660x_.png 660w, https://cdn.swbpg.com/t/38064/0e80cae2d9aa4589ac8a0aaacfebe520_s=860x_.png 860w, https://cdn.swbpg.com/t/38064/0e80cae2d9aa4589ac8a0aaacfebe520_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}